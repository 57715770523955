<template>
    <v-card class="mt-2">
        <v-toolbar
            dense
        >
            <v-toolbar-title>DATOS DE LA CITA</v-toolbar-title>
            <v-spacer/>
            <!-- Botón para recibir -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                    <v-btn
                    class="ml-5"
                    small
                    text
                    icon
                    v-on="on"
                    :disabled="cita.horaRecepcion!='' || cita.tipo==5 ||(cita.horaRecepcion==''&&cita.cip=='')"
                    >
                    <v-icon>mdi-login</v-icon>
                    </v-btn>
                </template>
                <span>Recibir</span>
            </v-tooltip>

            <!-- Botón para agregar signos vitales -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                    <v-btn
                        class="ml-5"
                        small
                        text
                        icon
                        v-on="on"
                        :disabled="!cita.horaRecepcion || cita.tipo==5"
                    >
                    <v-icon>mdi-ruler</v-icon>
                    </v-btn>
                </template>
                <span>Somatometría</span>
            </v-tooltip>
        </v-toolbar>
        <v-card-text>


            <v-expand-transition>
                <v-alert v-show="mensajePaciente"
                    type="warning"
                    
                >
                    <b>PACIENTE</b> tiene múltiples citas este día.
                </v-alert>
            </v-expand-transition>

            <v-expand-transition>
                <v-alert v-show="mensajeCita"
                    type="info"
                >
                    {{mensajeCitaText}}
                </v-alert>
            </v-expand-transition>

            <span class="title">Cita: </span><span v-html="cita.citaId"></span><br/>
            
            <span class="title">Paciente: </span>{{cita.paciente}}<br/>
            <span class="title">Edad: </span>{{cita.edad}}<br/>
            <span class="title">Médico: </span>{{cita.medico}}<br/>
            <span class="title">Consultorio: </span>{{cita.consultorio}}<br/>
            <span class="title">Fecha: </span>{{cita.fecha}}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props:{
        mensajeCita:Boolean,
        mensajeCitaText:String,

        mensajePaciente:Boolean,
        mensajePacienteText:String,

        cita:Object,
    },
    data: ()=>({
    }),
}
</script>

<style>

</style>