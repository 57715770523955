<template>
    <v-container fluid>
        <v-layout align-center justify-center>
            <v-card>
                <v-img
                    class="white--text align-top"
                    height="200px"
                    gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    :src="`/images/imgarea/somato.jpg`"
                >
                    <v-card-title justify-top>
                        <v-btn text icon to="/dashboard">
                            <v-icon class="white--text">
                                mdi-arrow-left
                            </v-icon>
                        </v-btn>
                        Somatometría
                    </v-card-title>
                </v-img>
                <v-card-text>
                    <v-row class="pt-5" justify="center" no-gutters>
                        <v-col class="px-1" xs=12 sm=12 md=6 lg=6 xl=6 cols="12">
                            <!-- ---------------- -->
                            <busca-form
                                @limpiaMensajes='limpiaMensajes'
                                @showMensajeCita='showMensajeCita'
                                @showMensajePaciente='showMensajePaciente'
                                @cargaDatosCita='cargaDatosCita'
                            />
                            <!-- ----------------------------------- -->
                        </v-col>
                        <v-col class="px-1" xs=12 sm=12 md=6 lg=6 xl=6 cols="12">
                            <v-card>
                                Múltiple citas
                            </v-card>
                        </v-col>

                        <v-col class="px-1" xs=12 sm=12 md=12 lg=12 xl=12 cols="12">


                            <!-- Pasar los parametros para que se muestren -->
                            <datos-cita
                            :mensajeCita='mensajeCita'
                            :mensajeCitaText='mensajeCitaText'

                            :mensajePaciente='mensajePaciente'
                            :mensajePacienteText='mensajePacienteText'
                            :cita='cita'
                            />


                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-layout>
    </v-container>
</template>

<script>
import Swal from "sweetalert2"
import 'sweetalert2/dist/sweetalert2.min.css'

import BuscaForm from '@/components/somato/BuscaCitaForm'
import DatosCita from '@/components/somato/DatosCita'

export default {
    components:{
        'busca-form': BuscaForm,
        'datos-cita': DatosCita
    },
    data: ()=>({
        mensajeCita:false,
        mensajeCitaText:'',

        mensajePaciente:false,
        mensajePacienteText:'',
        
        observacionesRecepcion:'',

        //dialogSomato: false,
        cita:{
            atiende: "",
            cip: '',
            citaId: '',
            conflicto: [],
            creado: "",
            edad: "",
            egresa: "",
            fecha: "",
            fechaCancelado: "",
            fechaDe: "",
            horaAtiende: "",
            horaEgreso: "",
            horaRecepcion: '',
            internamiento: '',
            medico: "",
            modificado: "",
            observaciones: '',
            observacionesCancelado: '',
            observacionesEgreso: "",
            observacionesIngreso: "",
            paciente: "",
            recibe: "",
            telefonos: "",
            tipo: '',
            versionCe: '',
            consultorio: '',
        },
        
        datosSomato:[
            //
        ],
    }),
    methods: {
        cargaDatosCita(dc){
            if (!dc.root){
                 Swal.fire({
                    icon: 'warning',
                    title: 'El número de cita no es válido',
                })
            }
            this.cita = dc.root
        },
        limpiaMensajes(){
            this.mensajeCita=false
            this.mensajeCitaText=''

            this.mensajePaciente=false
            this.mensajeCitaText=''
        },
        showMensajeCita(msg){
            this.mensajeCita=true
            this.mensajeCitaText=msg
        },
        showMensajePaciente(msg){
            this.mensajePaciente=true
            this.mensajePacienteText=msg
        },
    },
    
}
</script>

<style>

</style>