<template>
    <v-card class="pa-2">
        <v-form
            @submit="buscaCita"
            onSubmit="return false;"
            v-model="validForm"
            ref="formBusca"
        >
            <!-- Campo para buscar por FOLIO -->
            <v-text-field
                label="Folio"
                type="number"
                filled
                rounded
                clearable
                v-model="folio"
            ></v-text-field>
            <!-- Campo para buscar por PACIENTE -->
            <v-text-field
                label="Paciente"
                type="number"
                filled
                rounded
                clearable
                v-model="paciente"
            ></v-text-field>
            <!-- Botón para BUSCAR -->
            <v-btn
                type="submit"
                color="info"
                :loading="loadingBuscaCita"
            >
                Buscar
            </v-btn>
        </v-form>
    </v-card>
</template>

<script>

import Swal from "sweetalert2"
import 'sweetalert2/dist/sweetalert2.min.css'


export default {
    data: ()=>({
        validForm:false,
        loadingBuscaCita:false,
        folio:'',
        medico:'',
        paciente:'',
        
        
    }),

    methods:{
        async buscaCita(){
            this.loadingBuscaCita=true
            
            this.$emit('limpiaMensajes')
            //this.$emit('cargaDatosCita', {})

        
            if (this.folio > 100 || this.paciente > 10000009){

                if (this.folio > 100){
                    try{
                        let datosCita = await this.$http({
                            url: 'consulta/getCita',
                            method: 'GET',
                            params: {id: this.folio},
                        })
                        this.$emit('cargaDatosCita', datosCita.data)
                        //console.log(datosCita.data)
                        
                        this.loadingBuscaCita=false
                    } catch (err){
                        
                        console.log(err)
                        
                        this.$store.dispatch('security/revisaError',err)
                        this.loadingBuscaCita=false
                    }
                } 

                if (this.paciente > 10000009){
                    this.loadingBuscaCita=false
                } 
            } else {
               
               this.$emit('limpiaMensajes')
               //this.$emit('showMensajeCita','El número de paciente o folio no son válidos')
               Swal.fire({
                    icon: 'warning',
                    title: 'El número de folio o paciente no son válidos',
                    //text: 'Error de red',
                })
               this.loadingBuscaCita=false
            }
        }
    },
    
    watch:{
        folio(val){

            if (val){
                //this.$emit('limpiaMensajes')
                this.paciente = ''
            }
        },
        paciente(val){
            
            if (val){
                //this.$emit('limpiaMensajes')
                this.folio = ''
            }
        },
    },
}
</script>


<style>

</style>